import Header from '../components/headers'
import { signIn, signOut, useSession } from 'next-auth/react'

export default function Home() {
  const { data: session } = useSession()
  return (
    <div>
      <Header/>
      <nav className={"navbar"}>
        
          <div className={"signup"}>
            {session && session.user ? (
              <button onClick={() => signOut()}>Sign out</button>
            ) : (
              <button onClick={() => signIn()}>Sign in</button>
            )}
          </div>
      </nav>
      <main className={"main"}>

      </main>
    </div>
  )
}