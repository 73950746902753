import Link from 'next/link'

export default function Header() {
    return (
        <div>
            <Link href="/dashboard">ML Dashboard</Link>
            <p/>
            <Link href="/artist/rankings">Artist rankings</Link>
            <p/>
            <Link href="/cytoscape">Static Instagram Network Viewer</Link>
            <p/>
            <Link href="/cytoscape?type=1792">Static Instagram Network Viewer for 1792 dataset</Link>
            <p/>
            <Link href="/recent-magazines">recent-magazines</Link>
            <p/>
            <Link href="/artsy-categories">categories</Link>
            <p/>
            <Link href="/sigma">sigma</Link>
            <p/>
            <Link href="/auctions">upcoming / current auctions</Link>
            <p/>
        </div>
    )
}

